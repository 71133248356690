import React, { useEffect, useState } from 'react'
import './whoweare.css'
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux'
import { postInformationToDiscord } from '../../action/HomePage.action'

const WhoWeAre = () => {
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false)
  }

  const savedEmail = useSelector(state => state.home.email)

  const dispatch = useDispatch()

  function encodeEmailToBase64 (email) {
    console.log('Experience encodeEmailToBase64 email', email)
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  const handleEmailPopupOpen = event => {
    // alert("handleEmailPopupOpen function called")
    console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

    const encodedEmail = encodeEmailToBase64(savedEmail)
    const buttonText = event.target.innerText
    const buttonName = event.target.getAttribute('name')
    const href = event.target.getAttribute('href')

    if (buttonName == 'Enter JEX') {
      if (savedEmail == '') {
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    } else if (buttonName == 'Enter Discord') {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      }
    } else {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    }
  }

  return (
    <div id='whoweare' className='whoweare'>
      {/* <h4 style={{color: "black"}}>savedEmail{JSON.stringify(savedEmail)}</h4> */}
      <div className='whoweare-main d-flex flex-column container'>
        <div className='whoweare-section-title d-flex align-items-center justify-content-center flex-column'>
          <h1 className='experience-job-heading  font-family-description fs-xl-heading mobile-heading'>
            <span></span>
          </h1>
          <p className='fs-xl-para text-center'>
            <span className='terafac-title description-size' style={{fontFamily: 'Audiowide', fontSize: 24}}>TERAFAC </span><span style={{fontWeight:'bold'}}>brings smart welding solution</span>  through an AI-vision platform that allows off-the shelf robots to automatically scan and orient the parts, fine tune welding parameters and weld automatically. It minimizes the turn-around time, improves weld quality, and eliminates the dependency on highly skilled welders and on robot programmers.{' '}
          </p>
        </div>

        <div className='company-features d-flex align-items-center justify-content-center'>
          <div className='company-feature d-flex align-items-center justify-content-center'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare-icon4.png')}
                    alt=''
                    draggable="false"
                  />
                </div>
                <h4 className='text-center fs-xl-para'>
                Point and Click Software
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
              Automatically generates welding program based on operator input.
              </p>
              {/* <a
                name='Enter JEX'
                className='enter-jex-button text-decoration-none d-flex justify-content-center align-items-center'
                href='https://jex.terafac.live/'
                onClick={handleEmailPopupOpen}
              >
                Enter JEX
              </a> */}
            </div>
          </div>

          <div className='company-feature d-flex align-items-center justify-content-center'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare-icon5.png')}
                    alt=''
                    draggable="false"
                  />
                </div>
                <h4 className='col-12 text-center fs-xl-para'>
                AI-Vision for robots
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
              Detects orientation and weld seams to automatically adapt the program for deviations.
              </p>
              {/* <a
                name='Enter Discord'
                href='https://discord.gg/s22CzNab7E'
                onClick={handleEmailPopupOpen}
                className='enter-jex-button text-decoration-none d-flex justify-content-center align-items-center'
              >
                Enter Discord
              </a> */}
            </div>
          </div>

          <div className='company-feature d-flex align-items-center justify-content-center'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare-icon6.png')}
                    alt=''
                    draggable="false"
                  />
                </div>
                <h4 className='text-center fs-xl-para'>
                AI welder
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
              Fine tunes weld parameters automatically
              </p>
              {/* <a
                href='https://mentor.terafac.live/'
                className='enter-jex-button text-decoration-none d-flex justify-content-center align-items-center'
                onClick={handleEmailPopupOpen}
              >
                Talk to our Mentor
              </a> */}
            </div>
          </div>
        </div>
      </div>
      {showEmailPopup && (
        <EmailPopup
          buttonName={popupContent.buttonText}
          hrefUrl={popupContent.href}
          onClose={handleEmailPopupClose}
        />
      )}
    </div>
  )
}

export default WhoWeAre
