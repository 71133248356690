import React, { useState } from 'react';
import './contactusdesktop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { postInformationToDiscord, saveEmailId } from '../../action/HomePage.action';

const ContactUsDesktop = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({}); // ✅ State for validation errors
  const [showPopup, setShowPopup] = useState(false); // ✅ State for popup visibility
  const savedEmail = useSelector(state => state.home.email);
  const dispatch = useDispatch();

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // ✅ Clear errors when user starts typing
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  // ✅ Function to validate form
  const validateForm = () => {
    let newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Please enter your full name.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email.";
    } else if (!formData.email.includes("@")) {
      newErrors.email = "Email must include '@'.";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Please enter a message.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // ✅ Return `true` if no errors
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!validateForm()) {
      return; // ✅ Stop form submission if validation fails
    }

    console.log('Form data:', formData);
    dispatch(saveEmailId(formData.email));
    dispatch(postInformationToDiscord(formData));

    // ✅ Show popup after submitting
    setShowPopup(true);

    // ✅ Reset form fields
    setFormData({ fullName: '', email: '', message: '' });
  };

  return (
    <>
      <div className='col-12 d-flex justify-content-center align-items-center' style={{ minHeight: '60vh' }}>
        <div className='row mx-lg-5 d-flex flex-row justify-content-between main-container'>
          
          <div className='col-lg-3 col-md-12 col-sm-12 d-flex align-items-center'>
            <img src={require('../../assets/contact-us-pic.avif')} alt='Phone display' className='img-fluid mx-4' />
          </div>

          <div className='col-lg-4 mt-md-4 mt-sm-5 col-md-11 col-sm-11 text-box'>
            <h1 className='heading'>
              <strong className='fs-xl-heading'>Contact Us</strong>
            </h1>

            <form className='mb-4 mt-4' onSubmit={handleSubmit}>
              <div className='mb-2 mt-2'>
                <label className='form-label fs-xl-para round-outline ' htmlFor='fullName'>Full Name</label>
                <input
                  type='text'
                  className='form-control'
                  id='fullName'
                  name='fullName'
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
                {errors.fullName && <p className="error-message">{errors.fullName}</p>} {/* ✅ Show error */}
              </div>

              <div className='mb-3 position-relative'>
                <label className='form-label fs-xl-para' htmlFor='email'>E-mail</label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder={savedEmail}
                />
                {errors.email && <p className="error-message">{errors.email}</p>} {/* ✅ Show error */}
              </div>

              <div className='mb-3'>
                <label className='form-label fs-xl-para' htmlFor='message'>Message</label>
                <textarea
                  className='form-control message-area'
                  id='message'
                  name='message'
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
                {errors.message && <p className="error-message">{errors.message}</p>} {/* ✅ Show error */}
              </div>

              <button type='submit' className='btn btn-dark fs-xl-para'>Contact Us</button>
            </form>
          </div>

          <div className='contactus-box col-lg-3 col-md-12 mt-md-4 mt-sm-5 col-sm-12 mt-lg-5 mt-md-3 mt-sm-1'>
            <div className='contact-box'>
              <h4><strong className='contact-title fs-xl-para text-dark'>Contact</strong></h4>
              <p className='contact-email fs-sm-para'>info@terafac.com</p>
              <h4><strong className='office-title fs-xl-para text-dark'>Office</strong></h4>
              <p className='contact-location fs-sm-para'>Chandigarh, India</p>
            </div>

            <div className='icon-box'>
              <a href='https://www.youtube.com/@terafactech' target='_blank' rel='noreferrer' className='social-media-icon'>
                <FontAwesomeIcon color='#29c5ea' icon={faYoutube} />
              </a>
              <a href='https://www.linkedin.com/company/terafac-technologies-pvt-ltd/' target='_blank' rel='noreferrer' className='social-media-icon'>
                <FontAwesomeIcon color='#29c5ea' icon={faLinkedin} />
              </a>
              <a href='https://www.instagram.com/terafactech/' target='_blank' rel='noreferrer' className='social-media-icon'>
                <FontAwesomeIcon color='#29c5ea' icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* ✅ Thank-You Popup */}
      {showPopup && (
        <div className='email-popup'>
          <div className='email-popup-content'>
            <button className='email-close-button' onClick={() => setShowPopup(false)}>&times;</button>
            <p className='thank-you-message text-center'>
              Thank you for sharing your details! We will get back to you soon.<br />
              Meanwhile, talk to our manufacturing expert.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsDesktop;
