import React, { useState } from 'react';
import ContactSection from '../components/contact/ContactSection';
import ManufacturingHero from '../components/manufacturing/ManufacturingHero';
import Navbar2 from '../components/Navbar2/Navbar2';
import backgroundImage from '../assets/background_terafac.jpg';

const ContactPage = () => {

    return (
        <div>
            <div className="learning-page" style={{ backgroundColor: "#00061C" }}>
            <Navbar2 />
            <ManufacturingHero
                 backgroundImage={backgroundImage}
                text={[
                    {
                        content: "Lets Connect",
                        fontSize: { large: "3.5vw", default: "5.5vw", mobile: "5.5vw", tablet: "4vw" },
                        fontFamily: "Audiowide", fontWeight: '700',
                        color: "white"
                    },
                ]}
                className=""
                height={{ default: '60vh', mobile: '50vh' }}
            />
            <section>
                <ContactSection />
            </section>
            </div>
        </div>
    );
};
export default ContactPage;