import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './mobilefeature.css';
import { useMediaQuery } from 'react-responsive';

const MobileFeature = () => {
  const mobile = useMediaQuery({ query: '(max-width: 640px)' });

  // Destructure ref and inView from the useInView hook
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation will run only once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref} className="df-did-you-know-heading">
      <motion.h1
        className="did_you_know fs-xl-heading"
        style={{
          textAlign: 'center',
          fontFamily: 'poppins',
          margin: 0,
          padding: '60px 0 1px 0',
          backgroundColor: 'white',
          textEmphasisColor: '#00061C',
        }}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'} // Trigger animation when in view
        variants={fadeInUp}
        transition={{ duration: 1 }}
      >
        Did you know <span style={{ color: 'orange' }}>?</span>
      </motion.h1>
      <div className="df-terafac-live df-feature-column d-flex flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row">
        <motion.div
          className="df-feature-first-column col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5"
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'} // Trigger animation when in view
          variants={fadeInUp}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <div className="first-pic-div"></div>
          {/* <h1 className="text">
            Terafac<span className="live-span"> </span>
          </h1> */}
          <div className="second-pic-div" style={{ position: 'relative' }}>
            <img
              className="feature-first-pic"
              style={{
                position: '',
                top: 0,
                left: '0%',
                transform: 'translateX(-79%)',
              }}
              src={require('../../assets/welder_5.jpg')}
              alt=""
            />
            <img
              className="feature-second-pic"
              style={{
                position: 'absolute',
                top: 0,
                left: '33%',
                transform: 'translateX(-40%)',
              }}
              src={require('../../assets/welder_4.jpg')}
              alt=""
            />
            <img
              className="feature-third-pic"
              style={{
                position: 'absolute',
                bottom: 0,
                left: '99%',
                transform: 'translateX(-30%)',
              }}
              src={require('../../assets/welder_3.png')}
              alt=""
            />
          </div>
        </motion.div>
        <div className="feature-second-column col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
          <div className="col-10 features-div ms-0 ">
            <div className="icon-container">
              <img
                style={
                  mobile ? { width: 45, height: 45 } : { width: 56, height: 56 }
                }
                className="feature-icon"
                src={require('../../assets/features-icon4.png')}
                alt=""
              />
            </div>
            <h2>12 lakh welding professionals are needed to fill the skill gap.</h2>
          </div>
          <div className="col-10 features-div offset-2 ms-4">
            <div className="icon-container">
              <img
                style={
                  mobile ? { width: 45, height: 45 } : { width: 42, height: 42 }
                }
                className="feature-icon"
                src={require('../../assets/features-icon3.png')}
                alt=""
              />
            </div>
            <h2>No platform exists to save or analyze welding data.</h2>
          </div>
          <div className="col-10 features-div ms-0">
            <div className="icon-container">
              <img
                style={
                  mobile ? { width: 45, height: 45 } : { width: 42, height: 42 }
                }
                className="feature-icon"
                src={require('../../assets/features-icon2.png')}
                alt=""
              />
            </div>
            <h2>Welders face severe health hazardous risks on the job.</h2>
          </div>
          <div className="col-10 features-div offset-2 ms-4">
            <div className="icon-container">
              <img
                style={
                  mobile ? { width: 45, height: 45 } : { width: 42, height: 42 }
                }
                className="feature-icon"
                src={require('../../assets/features-icon1.png')}
                alt=""
              />
            </div>
            <h2>Manual welding wastes material and reduces efficiency.</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFeature;
