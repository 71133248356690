import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./blogdetail.css";
import data from "../../config/data.json";
import { Blurhash } from "react-blurhash";

// ResponsiveImage Component
const ResponsiveImage = ({ src, alt, className, hash, style }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div style={{ position: "relative", ...style }}>
      {!loaded && (
        <Blurhash
          hash={hash}
          width="100%"
          height="300px"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      <img
        src={src}
        alt={alt}
        className={className}
        style={{ display: loaded ? "block" : "none" }}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

// TopicBlurhashImage Component
const TopicBlurhashImage = ({ src, alt, className, style, hash }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div ref={containerRef} style={{ width: "100%" }}>
      {!imageLoaded && (
        <Blurhash
          hash={hash}
          width="100%"
          height="300px"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      <img
        src={src}
        alt={alt}
        className={className}
        style={{
          ...style,
          display: imageLoaded ? "block" : "none",
        }}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

const BlogDetail = () => {
  const { blogId } = useParams(); // Extract blogId from URL
  const navigate = useNavigate();

  // Find the blog by ID
  const post = data.blogs.find((blog) => blog.id === blogId);

  if (!post) {
    return (
      <div className="not-found">
        <h2>Blog Not Found</h2>
        <button className="back-button" onClick={() => navigate("/blog-post")}>
          Back to Blogs
        </button>
      </div>
    );
  }

  return (
    <div className="container-fluid" style={{ backgroundColor: "white" }}>
      {/* Blog Main Section */}
      <div className="container-fluid blog-main-section">
        <h2 className="blog-heading">{post.heading}</h2>
        <h6 className="blog-sub-heading">{post.sub_heading}</h6>

        {/* Top Image */}
        <ResponsiveImage
          src={post.top_image}
          alt="Blog main image"
          className="blog-image"
          hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
        />

        {/* Back Button */}
        <div className="blog-back blog-back-margin">
        <h4 
  className="blogger-name click grey" 
  onClick={() => {
    navigate("/");
    setTimeout(() => {
      const blogSection = document.getElementById("blogs");
      if (blogSection) {
        blogSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }}
>
  Back
</h4>
        </div>

        {/* Blogger Info */}
        <div className="blogger-div">
          <img
            src={post.founder_image}
            className="blogger-image"
            alt="blogger"
          />
          <p className="blogger-name">{post.founder_name}</p>
        </div>

        {/* Blog Explanation Section */}
        <div className="blog-explanation">
          {post?.topics?.map((item, index) => (
            <div key={index} className="blog-topic">
              {/* Image or Blurhash */}
              {item.image && (
                <TopicBlurhashImage
                  src={item.image}
                  alt={item.image_caption}
                  className="topic-images"
                  style={{ marginTop: 20, marginBottom: 20 }}
                  hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                />
              )}

              {/* Image Caption */}
              {item.image_caption && (
                <div className="blogger-image-caption">
                  <h6 className="blogger-name">{item.image_caption}</h6>
                </div>
              )}

              {/* Heading and Subheading */}
              {item.heading && <h4 className="topic-heading">{item.heading}</h4>}
              {item.sub_heading && (
                <h5 className="topic-subheading">{item.sub_heading}</h5>
              )}

              {/* Paragraphs */}
              {item.paragraphs &&
                item.paragraphs.map((paragraph, idx) => (
                  <p key={idx} className="blog-description">
                    {paragraph}
                  </p>
                ))}

              {/* Embedded Iframe */}
              {item.iframe && (
                <iframe
                  src={item.iframe}
                  title={item.sub_heading}
                  className="topic-iframe"
                  style={{
                    height: item.iframe.includes("youtube") ? "500px" : "70vh",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
              )}
            </div>
          ))}
        </div>

        {/* Back and Home Navigation */}
        <div className="blog-back click">
        <h5 className="blogger-name click">
          <span 
            className="grey" 
            onClick={() => {
              navigate("/");
              setTimeout(() => {
                const blogSection = document.getElementById("blogs");
                if (blogSection) {
                  blogSection.scrollIntoView({ behavior: "smooth" });
                }
              }, 100);
            }}
          >
            Back
          </span>{" "}
          <span className="grey" onClick={() => navigate("/")}>
            Home
          </span>
        </h5>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
