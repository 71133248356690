import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Carousel from "../Carousel/Carousel";


const BlogPost = () => {
  return (
    <div>
      <Navbar />
      <Carousel id="carousel-section" />
      <Footer id="Footer-section" />
    </div>
  );
};

export default BlogPost;
