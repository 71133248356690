import React from 'react'
import ManufacturingHero from '../components/manufacturing/ManufacturingHero';
import GroupCard from '../components/card/GroupCard';
import TalkToUsSection from '../components/talktous/TalkToUsSection';
import WhyChooseSection from '../components/whychoose/WhyChooseSection';
import Gallery from '../components/gallery/Gallery';
import LabSection from '../components/labsection/LabSection';
import Footer from '../components/Footer/Footer';
import CustomModal from '../components/modal/Modal';
import Navbar2 from '../components/Navbar2/Navbar2';

const TrainingPage = () => {

    const images = [
        require('../assets/images/training/training-gallery-1.png'),
        require('../assets/images/training/training-gallery-2.png'),
        require('../assets/images/training/training-gallery-3.png'),
        require('../assets/images/training/training-gallery-4.png'),
        require('../assets/images/training/training-gallery-5.png'),
        require('../assets/images/training/training-gallery-6.png'),
    ];

    const whyChooseData = {
        title: "Advanced Industrial Training Offerings",
        reasons: [
            "IIoT, AI, Industrial Automation, and Robotics etc modules covered.",
            "Expert-led workshops for practical insights.",
            "Hands-on experience for skill enhancement.",
            "Stay ahead with cutting-edge technology training.",
            "Unlock the potential of industrial innovation.",
           
        ]
    };


    const cardData = [
        {
            imageUrl: require("../assets/images/college-lab/college-lab-1.png"),
            icon: "ri-brush-4-line",
            title: "Industrial Automation Workshops",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-3.png"),
            icon: "ri-calendar-check-line",
            title: "Industrial IoT Workshops",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-8.png"),
            icon: "ri-movie-2-line",
            title: "Workshops for Schools",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-5.png"),
            icon: "ri-movie-2-line",
            title: "Digital Twin Workshops",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-6.png"),
            icon: "ri-movie-2-line",
            title: "Additive Manufacturing Workshops",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-7.png"),
            icon: "ri-movie-2-line",
            title: "AR/VR Workshops",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        }
    ];
    return (
        <div className='college-page' style={{ backgroundColor: "#00061C" }}>
            <Navbar2 />
            <ManufacturingHero
                backgroundImage={require("../assets/images/training-workshop-bg.png")}
                text={[
                    {
                        content: "Training & Workshops",
                        fontSize: { large: "3.5vw", default: "5.5vw", mobile: "5.5vw", tablet: "4vw" },
                        fontFamily: "Audiowide", fontWeight: '700'
                    },
                ]}
                className=""
            />
            <section>
                <h2 style={{width: "90%", alignItems: "center", alignSelf: "center"}} className='point-click-heading container-fluid'>
                    We offer Workshops in AI, Gen AI, Industrial Automation, IOT, and Industry 4.0, etc.
                </h2>
                <GroupCard
                    cards={cardData}
                />
            </section>
            <section>
                <div className="talk-to-us-manufacturing">
                    <TalkToUsSection
                        heading="Reserve your Institute’s personalized Training !"
                        buttonText="Apply Now!"
                    />
                </div>
                <CustomModal
                    modalTitle="Book a Training Session Demo!"
                    closeButtonName="Close"
                    actionButtonName="Book Demo"
                />
            </section>
            <section>
                <h2 className='point-click-heading container'>
                    Training & Workshop Gallery
                </h2>
                <Gallery
                    images={images}
                />
            </section>
            <section>
                <WhyChooseSection
                    title={whyChooseData.title}
                    reasons={whyChooseData.reasons}
                />
            </section>
            <CustomModal />
            <Footer />
        </div>
    )
}

export default TrainingPage;