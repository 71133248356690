import React, { useState } from 'react';
import "./contact.css";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { useDispatch } from 'react-redux';
import { saveEmailId, postInformationToDiscord } from '../../action/HomePage.action';

const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch();

    // Function to send form data to Discord
    const postSummaryToDiscord = async (data) => {
        try {
            const pseudoname = "chatgpt";
            const url = "https://discord.com/api/webhooks/1233360222018474036/0tvpmrNS_829AoQh2CdKy5HL-WKkW-Uby2y1uHjilvHJl31kx7z3A4hboaUVPtjdgOVD";

            const message = `**New Contact Form Submission**\n
            **Name:** ${data.name}\n
            **Email:** ${data.email}\n
            **Subject:** ${data.subject}\n
            **Message:** ${data.message}`;

            console.log("Sending to Discord:", message); // Debug log

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: pseudoname,
                    content: message,
                }),
            });

            const discordResponse = await response.text();
            console.log("Discord Response:", discordResponse);
        } catch (err) {
            console.error("Error posting to Discord:", err.message);
        }
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("Form Data Before Dispatch:", formData); // Debug log

        // Dispatch email and data to the store
        dispatch(saveEmailId(formData.email));
        dispatch(postInformationToDiscord(formData));

        // Send full form data to Discord webhook
        postSummaryToDiscord(formData);

        // Reset form fields after submission
        setFormData({ name: '', email: '', subject: '', message: '' });

        // Show the Thank You popup
        setShowPopup(true);

        // Hide the popup after 3 seconds
        setTimeout(() => {
            setShowPopup(false);
        }, 9000);
    };

    const handleNavigationClick = () => {
        // Scroll to top when clicking a navigation link
        window.scrollTo(0, 0);
    };

    return (
        <>
            <section id="contact" className="contact" data-aos="fade-up" data-aos-delay="100">
                <div className="container-fluid">
                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <div className="info-item d-flex flex-column justify-content-center align-items-center contact-card">
                                <i className="bi bi-map"><FaLocationDot /></i>
                                <h3>Our Address</h3>
                                <p>Chandigarh, India</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="info-item d-flex flex-column justify-content-center align-items-center contact-card">
                                <i className="bi bi-envelope"><MdEmail /></i>
                                <h3>Email Us</h3>
                                <p>info@terafac.com</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="info-item d-flex flex-column justify-content-center align-items-center contact-card">
                                <i className="bi bi-telephone"><FaPhoneAlt /></i>
                                <h3>Call Us</h3>
                                <p>+91 8264445150</p>
                            </div>
                        </div>
                    </div>

                    <div className="row gy-4 mt-1">
                        <div className="col-lg-6">
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13715.482640096103!2d76.74267993955075!3d30.750134400000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fede0acc93f01%3A0x3b3bd12db7d0c3d5!2sPanjab%20University%20Incubation%20Centre-RUSA!5e0!3m2!1sen!2sin!4v1714364736751!5m2!1sen!2sin" 
                                frameBorder="0" 
                                style={{ border: 0, width: '100%', height: '410px' }} 
                                allowFullScreen>
                            </iframe>
                        </div>

                        <div className="col-lg-6 bg-tranparent">
                            <form onSubmit={handleSubmit} role="form" className="php-email-form contact-card">
                                <div className="row gy-4">
                                    <div className="col-lg-6 form-group">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            className="form-control contact-card" 
                                            id="name" 
                                            placeholder="Your Name" 
                                            value={formData.name} 
                                            onChange={(e) => setFormData({...formData, name: e.target.value})} 
                                            required 
                                        />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input 
                                            type="email" 
                                            className="form-control contact-card" 
                                            name="email" 
                                            id="email" 
                                            placeholder="Your Email" 
                                            value={formData.email} 
                                            onChange={(e) => setFormData({...formData, email: e.target.value})} 
                                            required 
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control contact-card" 
                                        name="subject" 
                                        id="subject" 
                                        placeholder="Subject" 
                                        value={formData.subject} 
                                        onChange={(e) => setFormData({...formData, subject: e.target.value})} 
                                        required 
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea 
                                        className="form-control contact-card" 
                                        name="message" 
                                        rows="5" 
                                        placeholder="Message" 
                                        value={formData.message} 
                                        onChange={(e) => setFormData({...formData, message: e.target.value})} 
                                        required
                                    ></textarea>
                                </div>
                                <div className="my-3">
                                    <div className="loading">Loading</div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className='contact-card'>Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

            {/* Thank You Popup */}
            {showPopup && (
                <div className='email-popup'>
                    <div className='email-popup-content'>
                        <button className='email-close-button' onClick={() => setShowPopup(false)}>&times;</button>
                        <p className='thank-you-message'>
                            Thank you for sharing your details! We will get back to you soon.<br />
                            Meanwhile, talk to our manufacturing expert.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
}

export default ContactSection;
