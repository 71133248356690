import React from "react";

const TruncatedText = ({ text }) => {
  const maxLength =70;

  return (
    <div>
      {text.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
    </div>
  );
};

export default TruncatedText;