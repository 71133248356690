import React from "react";
import DemoSection from "../components/DemoSection/DemoSection";
import WhoWeAre from "../components/WhoWeAre/WhoWeAre";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

const AboutPage = () => {
  return (
    <div>
      <Navbar />
      <DemoSection id="demo-section" />
      <WhoWeAre id="about" />
      <Footer id="Footer-section" />
    </div>
  );
};

export default AboutPage;