import React, { useState } from 'react';
import './emailpopup.css';
import { useDispatch } from 'react-redux';
import { saveEmailId, postInformationToDiscord } from '../../action/HomePage.action';
import MentorPopup from '../MentorPopup/MentorPopup'; // Import MentorPopup

const Popup = ({ buttonName, hrefUrl, onClose }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState('');
  const [showMentorPopup, setShowMentorPopup] = useState(false);

  function handleEmail(event) {
    setEmail(event.target.value);
    setError(''); // Clear error when user starts typing
  }

  function encodeEmailToBase64(email) {
    return btoa(email);
  }

  function validateEmail(email) {
    if (!email) return "Please enter an email address.";
    if (!/\S+@\S+\.\S+/.test(email)) return "Please enter a valid email address.";
    return ""; // No error
  }

  function handleButton(e) {
    e.preventDefault();

    const validationError = validateEmail(email);
    if (validationError) {
      setError(validationError);
      return; // Stop execution if validation fails
    }

    dispatch(saveEmailId(email));
    dispatch(postInformationToDiscord(email));

    console.log('EmailPopup.js handleButton', buttonName);

    // Encode email
    const encodedEmail = encodeEmailToBase64(email);

    // Construct new URL
    const baseUrl = e.currentTarget.getAttribute('href');
    const fullUrl = `${baseUrl}?email=${encodedEmail}`;

    // Update browser history without reloading
    window.history.pushState({}, '', fullUrl);

    //  Show confirmation message
    setShowConfirmation(true);

    //  Set timeout to trigger MentorPopup **only if not already triggered**
    setTimeout(() => {
      setShowMentorPopup(true);
    }, 2000); // MentorPopup appears after 2 seconds
  }

  return (
    <>
      {!showMentorPopup ? (
        <div className='email-popup'>
          <button className='email-close-button' onClick={onClose}>
            &times;
          </button>

          {!showConfirmation ? (
            // First Popup: Email Input & Submit Button
            <div className='email-popup-content-div'>
              <div className='email'>
                <label className='email-first-name-label'></label>
                <input
                  className='email-phone-input'
                  placeholder='Email'
                  type='email'
                  onChange={handleEmail}
                  value={email}
                />
              </div>
              {error && <p className="error-message">{error}</p>} {/* Show validation error */}
              <button
                type='button'
                onClick={handleButton}
                className='redirect-btn'
              >
                {buttonName}
              </button>
            </div>
          ) : (
            // Second Popup: Thank You Message
            <div className='email-popup-content-div'>
              <p className='thank-you-message text-center'>
                Thank you for sharing your mail. We will get back to you soon.<br />
                Meanwhile, talk to our manufacturing expert.
              </p>
            </div>
          )}
        </div>
      ) : (
        // ✅ MentorPopup opens after submission
        <MentorPopup onClose={() => setShowMentorPopup(true)} />
      )}
    </>
  );
};

export default Popup;
