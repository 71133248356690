import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer"; // Import Intersection Observer
import "./desktopfeature.css";
import { useMediaQuery } from "react-responsive";
import icon1 from "../../assets/features-icon1.png";

const DesktopFeature = () => {
  const mobile = useMediaQuery({ query: "(max-width: 640px)" });

  // Intersection Observer for animation trigger
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.2, // Trigger when 20% of the section is visible
  });

  const featureIcons = {
    1: require("../../assets/features-icon1.png"),
    2: require("../../assets/features-icon2.png"),
    3: require("../../assets/features-icon3.png"),
    4: require("../../assets/features-icon4.png"),
  };
  

  // Variants for animations
  const fadeInUp = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref} className="df-did-you-know-heading">
      <motion.h1
        className="did_you_know fs-xl-heading"
        style={{
          textAlign: "center",
          fontFamily: "poppins",
          margin: 0,
          padding: "60px 0 1px 0",
          backgroundColor: "white",
          textEmphasisColor: "00061C",
          fontWeight: "600",
        }}
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Trigger animation when in view
        variants={fadeInUp}
        transition={{ duration: 1 }}
      >
         Did you know<span style={{ color: "orange" }}>?</span>
      </motion.h1>
      <div className="df-terafac-live df-feature-column d-flex flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row">
        <motion.div
          className="df-feature-first-column col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5"
          initial="hidden"
          animate={inView ? "visible" : "hidden"} // Trigger animation when in view
          variants={fadeInUp}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <div className="df-first-pic-div">
            <img
              className="df-feature-first-pic"
              src={require("../../assets/welder_5.jpg")}
              alt=""
              draggable ={false}
            />
          </div>
          <div className="df-second-pic-div">
            <motion.img
              className="df-feature-second-pic"
              src={require("../../assets/welder_4.jpg")}
              alt=""
              draggable = {false}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 0.4 }}
            />
            <motion.h1
              className="fs-xl-heading"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 0.6 }}
            >
              TERAFAC <span style={{ fontWeight: 400 }}></span>
            </motion.h1>
            <motion.img
              className="df-feature-third-pic"
              src={require("../../assets/welder_3.png")}
              alt=""
              draggable={false}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 0.8 }}
            />
          </div>
        </motion.div>
        <motion.div
          className="df-feature-second-column col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7"
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 1, delay: 1 }}
        >
          {[
            "12 lakh welding professionals are needed to fill the skill gap.",
            "No platform exists to save or analyze welding data.",
            "Welders face severe health hazardous risks on the job.",
            "Manual welding wastes material and reduces efficiency.",
          ].map((text, index) => (
            <motion.div
              key={index}
              className={`col-9 df-features-div ${index % 2 === 1 ? "offset-2" : ""}`}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 1 + index * 0.2 }}
            >
              <div className="df-icon-container">
                <img
                  style={mobile ? { width: 25, height: 25 } : { width: 70, height: 70 }}
                  className="df-feature-icon"
                  src={index === 3 ? icon1 : require(`../../assets/features-icon${4 - index}.png`)}
                   alt=""
                   draggable="false"
                />
              </div>
              <h2 className="fs-xl-para">{text}</h2>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default DesktopFeature;
