import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import JobExperienceCenter from '../components/JobExperienceCenter/index';
import DemoSection from '../components/DemoSection/DemoSection';
import Feature from "../components/Feature/index";
import Carousel from '../components/Carousel/Carousel';
import ContactUs from '../components/ContactUs/index';
import WhoWeAre from '../components/WhoWeAre/WhoWeAre';
import MentorPopup from '../components/MentorPopup/MentorPopup';
import Footer from '../components/Footer/Footer';
import Manufacturing from '../components/manufacturing/SmartManufacturing'


const HomePage = () => {

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    window.onload = function() {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 9000); // 3 seconds after the screen is fully loaded

      return () => clearTimeout(timer);
    };
  }, []);

  const handleClose = () => {
    setShowPopup(false);

    setTimeout(() => {
      setShowPopup(true);
    }, 60000); // Reappear after 1 minute
  };

  return (
    <div>
      <Navbar />
      <Hero id="hero-section" />
      <JobExperienceCenter  id="experience-section" />
      <DemoSection id="demo-section" />
      <Feature id="feature-section" />
      <WhoWeAre id='about' />
      <section id="blogs">
      </section>
      <Carousel id="carousel-section" />
      <ContactUs id="contact-section" />
      <Footer id="Footer-section"/>
      {/* <Manufacturing id="Manufacturing-section"/> */}
      

      {showPopup && <MentorPopup onClose={handleClose} />}
    </div>
  )
}

export default HomePage;