import React, { useState } from 'react';
import Navbar2 from '../components/Navbar2/Navbar2';
import ManufacturingHero from '../components/manufacturing/ManufacturingHero';
import GroupCard from '../components/card/GroupCard';
import WhyChooseSection from '../components/whychoose/WhyChooseSection';
import TalkToUsSection from '../components/talktous/TalkToUsSection';
import Gallery from '../components/gallery/Gallery';
import LabSection from '../components/labsection/LabSection';
import Footer from '../components/Footer/Footer';
import CustomModal from '../components/modal/Modal';
import './page.css'

const LabForCollege = () => {

    const images = [
        require('../assets/images/gallery/gallery-1.png'),
        require('../assets/images/gallery/gallery-2.png'),
        require('../assets/images/gallery/gallery-3.png'),
        require('../assets/images/gallery/gallery-4.png'),
        require('../assets/images/gallery/gallery-5.png'),
        require('../assets/images/gallery/gallery-6.png'),
    ];

    const cardData = [
        {
            imageUrl: require("../assets/images/college-lab/college-lab-1.png"),
            icon: "ri-brush-4-line",
            title: "TFT ED-RIG",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-2.png"),
            icon: "ri-calendar-check-line",
            title: "Automation Station",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-3.png"),
            icon: "ri-movie-2-line",
            title: "IOT Station",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-4.png"),
            icon: "ri-movie-2-line",
            title: "Robotic Arm",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-5.png"),
            icon: "ri-movie-2-line",
            title: "Digital Twin",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-6.png"),
            icon: "ri-movie-2-line",
            title: "Additive Manufacturing",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-7.png"),
            icon: "ri-movie-2-line",
            title: "AR/VR",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/college-lab/college-lab-8.png"),
            icon: "ri-movie-2-line",
            title: "Mini-Factory",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        }

    ];

    const labData = [
        {
            imageUrl: require("../assets/images/college-lab/college-lab-3.png"),
            icon: "ri-brush-4-line",
            title: "IoT (Cyber Physical System Lab)",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/labs/automation-lab.png"),
            icon: "ri-calendar-check-line",
            title: "Automation Labs for Blue Collars",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/labs/smart-manufacturing-lab.png"),
            icon: "ri-movie-2-line",
            title: "Smart Manufacturing & Industry 4.0",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        },
        {
            imageUrl: require("../assets/images/labs/mini-factory-lab.png"),
            icon: "ri-movie-2-line",
            title: "Mini-Factory for Schools",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#"
        }]

        const whyChooseData = {
            title: "Technologies covered ",
            reasons: [
                "Automation: Enhancing efficiency through streamlined processes.",
                "Robotics: Designing intelligent machines for various tasks.",
                "Industry 4.0: Revolutionizing manufacturing with advanced technologies.",
                "IoT: Connecting devices for data-driven insights and automation.",
                "Embedded Systems: Tailoring computing systems for specific functions.",
                "AI: Empowering machines to learn and make autonomous decisions."
            ]
        };

    return (
        <div className='learning-page' style={{ backgroundColor: "#00061C" }}>
            <Navbar2 />
            <ManufacturingHero
                backgroundImage={require("../assets/images/college-top-hero-bg.png")}
                text={[
                    {
                        content: "Industry 4.0 Labs For Institutes ",
                        fontSize: { large: "3.5vw", default: "5.5vw", mobile: "5.5vw", tablet: "4vw" },
                        fontFamily: "Audiowide", fontWeight: '400',
                        backgroundColor: "#000000",
                    },
                ]}
                className=""
            />
            <section>
                <div className="robot-platform">
                    <ManufacturingHero
                        backgroundImage={require("../assets/images/college-lab-bg.png")}
                        text={[
                            {
                                content: "Revolutionize Hands-on learning with our lab setup at your college!",
                                fontSize: { large: "2.5vw", default: "2.8vw", mobile: "5.5vw" },
                                fontFamily: "Roboto Slab",
                                fontWeight: '700'
                            },
                        ]}
                        className="container-fluid  p-0"
                        height={{ default: "60vh", mobile: "42vh" }}
                        borderRadius="12px"
                        width="68%"
                    />
                </div>
            </section>
            <section>
                <h2 className='lab-section-heading' style={{ color: "white" }}>
                    Explore our Different types of labs for powering Smart Manufacturing and Industry 4.0, IoT, robotics, AI analytics, and cyber - physical systems :-
                </h2>
                <LabSection
                    cards={labData}
                />
            </section>
            <h2 className='lab-card-heading'>
                Labs consist of a combination of the following stations
            </h2>
            <GroupCard
                cards={cardData}
            />
            <section>
                <h2 className='automation-heading'>
                    Automation & Industry 4.0 lab gallery
                </h2>
                <Gallery
                    images={images}
                />
            </section>
            <section>
                {/* <div className="talk-to-us-manufacturing"> */}
                {/* <div className="talk-to-us-manufacturing">
                    <TalkToUsSection
                        heading="Reserve your welding robot demo today!"
                        buttonText="Talk To Us"
                        onClick={handleOpenModal} // ✅ Trigger modal on button click
                    />
                </div>

                </div>
                <CustomModal
                    showModal={showModal}
                    onClose={() => setShowModal(false)} // ✅ Close modal function
                    modalTitle="Talk To Us"
                    closeButtonName="Close"
                    actionButtonName="Submit"
                /> */}
            </section>
            <section>
                <WhyChooseSection
                    title={whyChooseData.title}
                    reasons={whyChooseData.reasons}
                />
            </section>
            <Footer />
        </div>
    )
}

export default LabForCollege;