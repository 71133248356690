import React, { useState } from 'react';
import OtherHero from '../components/otherpageshero/OtherHero';
import Navbar2 from '../components/Navbar2/Navbar2';
import ManufacturingHero from '../components/manufacturing/ManufacturingHero';
import TalkToUsSection from '../components/talktous/TalkToUsSection';
import WhyChooseSection from '../components/whychoose/WhyChooseSection';
import GroupCard from '../components/card/GroupCard';
import LearningAbout from '../components/about/LearningAbout';
import Footer from '../components/Footer/Footer';
import CustomModal from '../components/modal/Modal';
import './page.css';

const LearningPage = () => {
    const [showModal, setShowModal] = useState(false); // ✅ State to control modal visibility

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const whyChooseData = {
        title: "Technologies covered ",
        reasons: [
            "Automation: Enhancing efficiency through streamlined processes.",
            "Robotics: Designing intelligent machines for various tasks.",
            "Industry 4.0: Revolutionizing manufacturing with advanced technologies.",
            "IoT: Connecting devices for data-driven insights and automation.",
            "Embedded Systems: Tailoring computing systems for specific functions.",
            "AI: Empowering machines to learn and make autonomous decisions."
        ]
    };

    const cardData = [
        {
            imageUrl: require("../assets/images/all-in-one.png"),
            icon: "ri-brush-4-line",
            title: "All in One",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua.",
            linkUrl: "#",
        },
        {
            imageUrl: require("../assets/images/hands-on-learning.jpg"),
            icon: "ri-calendar-check-line",
            title: "Hands on Learning",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam.",
            linkUrl: "#",
        },
        {
            imageUrl: require("../assets/images/versatility.png"),
            icon: "ri-movie-2-line",
            title: "Versatility",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos.",
            linkUrl: "#",
        }
    ];

    return (
        <div className='learning-page' style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
            <Navbar2 />
            <ManufacturingHero
                backgroundImage={require("../assets/images/learning/learning-hero-bg.png")}
                text={[{ content: "Plug-n-Play Mini-Factory For Schools", fontSize: { large: "3.5vw", default: "5.5vw", mobile: "5.5vw", tablet: "4vw" }, fontFamily: "Audiowide", fontWeight: '500' }]}
            />
            <section>
                <div className="robot-platform">
                    <ManufacturingHero
                        backgroundImage={require("../assets/images/learning/learning-unlease.png")}
                        text={[{ content: "Unleash the Power of Industry 4.0 in your Classroom!", fontSize: { large: "2.5vw", default: "3.6vw", mobile: "6vw" }, fontFamily: "Roboto Slab", fontWeight: '700' }]}
                    />
                </div>
            </section>
            <section>
                <div className="mini-factory-div">
                    <LearningAbout
                        imageurl={require("../assets/images/all-in-one.png")}
                        heading="We equip classrooms with plug-n-play Mini-Factory that enables hands-on learning of Industry 4.0 technologies."
                        description="Founded with a vision to empower smart manufacturing and smart learning, Terafac Technologies was established in 2021."
                    />
                </div>
            </section>
            <section>
                <div className="home-card">
                    <GroupCard cards={cardData} />
                </div>
            </section>
            <section>
                <div className="talk-to-us-manufacturing">
                    {/* <TalkToUsSection
                        heading="Reserve your school's personalized demo now!"
                        buttonText="Book Demo!"
                        onClick={openModal} // ✅ Open modal on click
                    /> */}
                </div>
                {/* ✅ Pass `showModal` & `closeModal` to CustomModal */}
                {/* {showModal && (
                    <CustomModal
                        modalTitle="Perfect for schools, Book Demo Now"
                        closeButtonName="Close"
                        actionButtonName="Book Demo"
                        onClose={closeModal}
                    />
                )} */}
            </section>
            <section>
                <WhyChooseSection title={whyChooseData.title} reasons={whyChooseData.reasons} />
            </section>
            <Footer />
        </div>
    );
};

export default LearningPage;
