import React from 'react'
import './DemoSection.css'
import YoutubePlayer from '../Youtube/YoutubePlayer'


const DemoSection = () => {
  return (
    <section id="demo" className="demo">
      <div className="container">
        <div className="center-box">
          
          <p className="description">
            <span className="brand-name" style={{ fontFamily: 'Audiowide, sans-serif', fontSize: '2.5rem', fontWeight: 'bold', color: '#00061C' }}>
              TERAFAC&nbsp; 
            </span>{" "}
              is bringing AI and automation to the world of manufacturing. We’ll enable factories of the future by giving intelligence to shop floor machines, starting with welding robots.
          </p>
        </div>
      </div>
    </section>
  );
};
          
            {/* <p>
              Esse voluptas cumque vel exercitationem. Reiciendis est hic
              accusamus. Non ipsam et sed minima temporibus laudantium. Soluta
              voluptate sed facere corporis dolores excepturi. Libero laboriosam
              sint et id nulla tenetur. Suscipit aut voluptate.
            </p> */}
            {/* <ol className='list-group list-group-numbered'>
              <li className='list fs-xl-para'>Visit terafac.live</li>
              <li className='list fs-xl-para'>
                Click "Explore Jobs" and choose your desired job
              </li>
              <li className='list fs-xl-para'>Fill in your details and click "Simulate"</li>
              <li className='list fs-xl-para'>
                Sign up and install the Discord app if needed
              </li>
              <li className='list fs-xl-para'>
                Access the Terafac Live Discord server to start onboarding
              </li>
              <li className='list fs-xl-para'>
                Once onboarded, your virtual manager will welcome you
              </li>
              <li className='list fs-xl-para'>
                Happy working !
              </li>
            </ol> */}
            {/* <button className='learn-more-btn'>Learn more</button> */}
            {/* <a href='https://discord.gg/J5WKkkzEXG'>
              <button className='button col-sm-8  col-lg-6 col-md-6 text-decoration-none font-family-button fs-lg-4 custom-font-size-button my-3' >
                Learn More
              </button>
            </a> */}
          // </div>
          {/* <div>
            <YoutubePlayer />
          </div> */}
          // <div className='video-inner-div col-xl-6 col-lg-6 d-flex align-items-center justify-content-center'>
            {/* <div className='video-left-div'></div>
            <div className='video-right-div'></div> */}
            // <div className='col-xl-4 col-lg-6 video-box d-flex justify-content-center align-items-center'>
              {/* <a
                href='https://www.youtube.com/watch?v=ktTH5xwTgho'
                className='glightbox play-btn mb-4'
              /> */}
//                <YoutubePlayer />
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

export default DemoSection
