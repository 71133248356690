import React, { useState, useEffect } from 'react'
import './desktopjobexperience.css'
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux'
import { postInformationToDiscord } from '../../action/HomePage.action'

const DesktopJobExperience = () => {
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })

  const savedEmail = useSelector(state => state.home.email)

  const dispatch = useDispatch()

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false)
  }

  // function encodeEmailToBase64 (email) {
  //   console.log('Experience encodeEmailToBase64 email', email)
  //   const encodedEmail = btoa(email)
  //   return encodedEmail
  // }

  // const handleEmailPopupOpen = event => {
  //   // alert("handleEmailPopupOpen function called")
  //   console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

  //   const encodedEmail = encodeEmailToBase64(savedEmail)
  //   const buttonText = event.target.innerText
  //   const buttonName = event.target.getAttribute('name')
  //   const href = event.target.getAttribute('href')

  //   if (buttonName == 'Enter JEX') {
  //     if (savedEmail == '') {
  //       event.preventDefault()
  //       const baseUrl = event.currentTarget.getAttribute('href')
  //       const fullUrl = `${baseUrl}`
  //       window.location.href = fullUrl
  //     } else {
  //       dispatch(postInformationToDiscord(savedEmail))
  //       event.preventDefault()
  //       const baseUrl = event.currentTarget.getAttribute('href')
  //       const fullUrl = `${baseUrl}?email=${encodedEmail}`
  //       window.location.href = fullUrl
  //     }
  //   } else if (buttonName == 'Enter Discord') {
  //     if (savedEmail == '') {
  //       // alert(href)
  //       event.preventDefault()
  //       setPopupContent({ href, buttonText })
  //       setShowEmailPopup(true)
  //     } else {
  //       dispatch(postInformationToDiscord(savedEmail))
  //       event.preventDefault()
  //       const baseUrl = event.currentTarget.getAttribute('href')
  //       const fullUrl = `${baseUrl}`
  //       window.location.href = fullUrl
  //     }
  //   } else {
  //     if (savedEmail == '') {
  //       // alert(href)
  //       event.preventDefault()
  //       setPopupContent({ href, buttonText })
  //       setShowEmailPopup(true)
  //     } else {
  //       dispatch(postInformationToDiscord(savedEmail))
  //       event.preventDefault()
  //       const baseUrl = event.currentTarget.getAttribute('href')
  //       const fullUrl = `${baseUrl}?email=${encodedEmail}`
  //       window.location.href = fullUrl
  //     }
  //   }
  // }

  // return (
  //   <section className='dj-job-experience-center dj-bg-color container d-flex justif-content-center align-items-center'>
  //     <div className='dj-experience-top d-flex justify-content-center align-items-center'>
  //       <div className='dj-experience-top-part col-xl-6 col-lg-5 col-md-8'>
  //         <h5 className='dj-font-family-description'>
  //           Experience jobs in virtual environment !
  //         </h5>
  //         <p className='dj-font-family-description fs-5'>
  //           Work on real and simulated tools.
  //           <br /> Interact with managers, peers and customers to get tasks done
  //           as you would do in a real job.
  //         </p>
  //       </div>
  //     </div>
  //     <div className='dj-job-features row d-flex align-items-center justify-content-center'>
  //       <div className='col-md-5 col-xl-5 d-flex justify-content-center align-items-center '>
  //         <div className='dj-btn-container'>
  //           <p className='dj-font-family-description'>
  //             Enter{' '}
  //             <b className='dj-font-family-description fs-sm-6'>
  //               Job Experience Center (JEX)
  //             </b>{' '}
  //             to explore and experience simulated jobs.
  //           </p>
  //           <a
  //             name='Enter JEX'
  //             href='https://jex.terafac.live/'
  //             onClick={handleEmailPopupOpen}
  //             className='dj-jex-buttons dj-font-family-button col-lg-6 col-md-6 text-decoration-none fs-5'
  //           >
  //             Enter JEX
  //           </a>
  //         </div>
  //       </div>
  //       <div className='col-md-5 col-xl-5 d-flex justify-content-center align-items-center'>
  //         <div className='dj-btn-container'>
  //           <p className='dj-font-family-description'>
  //             Join our <b>discord</b> server to watch other users in action
  //           </p>
  //           <a
  //             name='Enter Discord'
  //             href='https://discord.gg/J5WKkkzEXG'
  //             onClick={handleEmailPopupOpen}
  //             className='dj-jex-buttons dj-font-family-button col-lg-6 col-md-6 text-decoration-none fs-5'
  //           >
  //             Enter Discord
  //           </a>
  //         </div>
  //       </div>
  //     </div>
  //     {showEmailPopup && (
  //       <EmailPopup
  //         buttonName={popupContent.buttonText}
  //         hrefUrl={popupContent.href}
  //         onClose={handleEmailPopupClose}
  //       />
  //     )}
  //   </section>
  // )
}

export default DesktopJobExperience
