import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './tabletfeature.css';
import { useMediaQuery } from 'react-responsive';

const TabletFeature = () => {
  const mobile = useMediaQuery({ query: '(max-width: 640px)' });

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const features = [
    "12 lakh welding professionals are needed to fill the skill gap.",
    "No platform exists to save or analyze welding data.",
    "Welders face severe health hazardous risks on the job.",
    "Manual welding wastes material and reduces efficiency.",
  ];

  return (
    <div ref={ref} className="tf-feature-column d-flex flex-row">
      <motion.div
        className="tf-feature-first-column col-4"
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={fadeInUp}
        transition={{ duration: 1, delay: 0.2 }}
      >
        <div className="tf-first-pic-div">
          <img
            className="tf-feature-first-pic"
            src={require('../../assets/welder_5.jpg')}
            alt=""
          />
        </div>
        <div className="tf-second-pic-div">
          <img
            className="tf-feature-second-pic"
            src={require('../../assets/welder_4.jpg')}
            alt=""
          />
          <h1 className="tf-terafac-live">TERAFAC</h1>
          <img
            className="tf-feature-third-pic"
            src={require('../../assets/welder_3.png')}
            alt=""
          />
        </div>
      </motion.div>
      <div className="tf-feature-second-column col-8">
        {features.map((text, index) => (
          <motion.div
            key={index}
            className={`col-9 tf-features-div ${index % 2 === 1 ? "offset-2" : ""}`}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={fadeInUp}
            transition={{ duration: 1, delay: index * 0.2 }}
          >
            <div className="tf-icon-container">
              <img
                style={mobile ? { width: 65, height: 65 } : { width: 60, height: 60 }}
                className="tf-feature-icon"
                src={require(`../../assets/features-icon${4 - index}.png`)}
                alt=""
              />
            </div>
            <h2>{text}</h2>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default TabletFeature;
