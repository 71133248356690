import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import BlogPost from "./components/blog/blog"; // Blog list component
import BlogDetail from "./components/blog/blogdetail"; // Blog detail component
import ManufacturinPage from "./pages/ManufacturinPage"; // Importing Smart Manufacturing Page
import LabForCollege from "./pages/LabForCollege";
import LearningPage from "./pages/LearningPage";
import ContactPage from "./pages/ContactPage";
import TrainingPage from "./pages/TrainingPage";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            {/* Home Page */}
            <Route path="/" element={<HomePage />} />

            {/* About Page */}
            <Route path="/about-us" element={<AboutPage />} />

            {/* Blog List Page */}
            <Route path="/blog-post" element={<BlogPost />} />

            {/* Blog Detail Page */}
            <Route path="/blog/:blogId" element={<BlogDetail />} />
            <Route path="/tag/:blogId" element={<BlogDetail />} />

            {/* Smart Manufacturing Page */}
            <Route path="/smart-manufacturing" element={<ManufacturinPage />} />

             {/*  Lab for College / University Page */}
             <Route path="/lab-for-college" element={<LabForCollege />} />

              {/*  Lab for 6th-12th Grade Page */}
            <Route path="/lab-for-school" element={<LearningPage />} />

            {/*    Contact Us Page Route */}
            <Route path="/contact" element={<ContactPage />} />

            <Route path='/trainingworkshop' element={<TrainingPage />} />

            {/* Redirect any unknown routes to Home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
