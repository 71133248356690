import React, { useState, useEffect } from 'react';
import './mentorpopup.css';

const Popup = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let interval;

    if (!isVisible) {
      // Set timeout only when the popup is closed
      interval = setTimeout(() => {
        setIsVisible(true);
      }, 10000);
    }

    return () => clearTimeout(interval); // Cleanup
  }, [isVisible]); // Runs when isVisible changes

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className='popup'>
          <button className='close-button' onClick={handleClose}>
            &times;
          </button>
          <div className='popup-main-div'>
            <img
              className='popup-terafac-logo'
              src={require('../../assets/mentor-logo.png')}
              alt='Terafac Mentor Logo'
            />
            <div className='popup-content-div'>
              <h2 className='popup-heading'>Terafac Manufacturing expert</h2>
              <p className='popup-paragraph'>Let’s build solutions together!</p>
              <a
                type='button'
                className='start-chating-btn'
                href='https://expert.terafac.com'
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat with us now
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
