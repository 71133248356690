import React, { useState } from 'react';
import './contactusmobile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useDispatch } from 'react-redux';
import { postInformationToDiscord, saveEmailId } from '../../action/HomePage.action';

const ContactUsMobile = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({}); // ✅ State for validation errors
  const [showPopup, setShowPopup] = useState(false); // ✅ State for popup visibility

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    // ✅ Clear errors when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [id]: '' }));
  };

  // ✅ Function to validate form
  const validateForm = () => {
    let newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Please enter your full name.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email.";
    } else if (!formData.email.includes("@")) {
      newErrors.email = "Email must include '@'.";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Please enter a message.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // ✅ Return `true` if no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // ✅ Stop form submission if validation fails
    }

    console.log('Form data:', formData);
    dispatch(saveEmailId(formData.email));
    dispatch(postInformationToDiscord(formData));

    // ✅ Show popup after submitting
    setShowPopup(true);

    // ✅ Reset form fields
    setFormData({ fullName: '', email: '', message: '' });
  };

  return (
    <>
      <div className='col-12 d-flex justify-content-center align-items-center' style={{ minHeight: '70vh' }}>
        <div className='row mx-lg-5 d-flex flex-row justify-content-center cm-main-container'>
          <div className='text-box d-flex justify-content-center align-items-center flex-column'>
            <h1 className='cm-heading'>
              <strong className='text-center'>Contact Us</strong>
            </h1>

            <form className='mb-2 mt-2 d-flex justify-content-center align-items-center flex-column' onSubmit={handleSubmit}>
              <div className='mb-1 col-12'>
                <label className='cm-form-label' htmlFor='fullName'>Full Name</label>
                <input
                  type='text'
                  className='cm-form-control'
                  id='fullName'
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && <p className="error-message">{errors.fullName}</p>} {/* ✅ Show error */}
              </div>

              <div className='mb-1 col-12'>
                <label className='cm-form-label' htmlFor='email'>E-mail</label>
                <input
                  type='email'
                  className='cm-form-control'
                  id='email'
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="error-message">{errors.email}</p>} {/* ✅ Show error */}
              </div>

              <div className='mb-1 col-12'>
                <label className='cm-form-label' htmlFor='message'>Message</label>
                <textarea
                  className='cm-form-control cm-message-area'
                  id='message'
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && <p className="error-message">{errors.message}</p>} {/* ✅ Show error */}
              </div>

              <button type='submit' className='cm-btn cm-btn-dark'>Contact Us</button>
            </form>
          </div>

          <div className='cm-icon-box d-flex flex-row justify-content-center align-items-center'>
            <a href='https://www.youtube.com/@terafactech' target='_blank' rel='noreferrer' className='cm-social-media-icon'>
              <FontAwesomeIcon color='#29c5ea' icon={faYoutube} />
            </a>
            <a href='https://www.linkedin.com/company/terafac-technologies-pvt-ltd/' target='_blank' rel='noreferrer' className='cm-social-media-icon'>
              <FontAwesomeIcon color='#29c5ea' icon={faLinkedin} />
            </a>
            <a href='https://www.instagram.com/terafactech/' target='_blank' rel='noreferrer' className='cm-social-media-icon'>
              <FontAwesomeIcon color='#29c5ea' icon={faInstagram} />
            </a>
          </div>

          <div className='d-flex flex-row justify-content-between align-items-center'>
            <div className='cm-office-box d-flex flex-column justify-content-end align-items-'>
              <h4 className='p-0 m-0'><a className='cm-office-title'>Contact</a></h4>
              <a className='cm-contact-location text-decoration-none'>info@terafac.com</a>
            </div>

            <div className='cm-office-box d-flex flex-column justify-content-end align-items-'>
              <h4 className='p-0 m-0'><a className='cm-office-title'>Office</a></h4>
              <a className='cm-contact-location text-decoration-none'>Chandigarh, India</a>
            </div>
          </div>
        </div>
      </div>

      {/* ✅ Thank-You Popup */}
      {showPopup && (
        <div className='email-popup'>
          <div className='email-popup-content'>
            <button className='email-close-button' onClick={() => setShowPopup(false)}>&times;</button>
            <p className='thank-you-message text-center'>
              Thank you for sharing your details! We will get back to you soon.<br />
              Till then, why don't you talk to our Manufacturing expert.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsMobile;
