import React, { useState } from 'react';
import "./footer.css";
import { FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveEmailId, postInformationToDiscord } from '../../action/HomePage.action';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [showPopup, setShowPopup] = useState(false); // ✅ State for popup visibility
    const dispatch = useDispatch();

    const postSummaryToDiscord = async (email) => {
        try {
            const pseudoname = "chatgpt";
            const url = "https://discord.com/api/webhooks/1233360222018474036/0tvpmrNS_829AoQh2CdKy5HL-WKkW-Uby2y1uHjilvHJl31kx7z3A4hboaUVPtjdgOVD";

            const message = `Subscriber: **${email}**`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: pseudoname,
                    content: `New newsletter subscription from: ${email}`,
                }),
            });
            console.log("Discord Response:", await response.text());
        } catch (err) {
            console.error("Error posting to Discord:", err.message);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email.trim() === "") return; // Prevent empty submissions

        dispatch(saveEmailId(email));
        dispatch(postInformationToDiscord(email));

        // ✅ Send email to Discord via webhook
        postSummaryToDiscord(email);

        // ✅ Show thank-you popup
        setShowPopup(true);

        // ✅ Clear input field after submission
        setEmail('');
    };

    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-info">
                                    <h3>Terafac</h3>
                                    <p>
                                        Chandigarh, India<br />
                                        <strong>Phone:</strong> +91 8264445150<br />
                                        <strong>Email:</strong> info@terafac.com<br />
                                    </p>
                                    <div className="social-links mt-3">
                                        <a href="https://www.youtube.com/@terafactech" target="_blank" rel="noreferrer" className="youtube">
                                            <FaYoutube />
                                        </a>
                                        <a href="https://www.instagram.com/terafactech/" target="_blank" rel="noreferrer" className="instagram">
                                            <FaInstagram />
                                        </a>
                                        <a href="https://www.linkedin.com/company/terafac-technologies-pvt-ltd/?viewAsMember=true" target="_blank" rel="noreferrer" className="linkedin">
                                            <FaLinkedinIn />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links mt-1">
                                <h4>Company</h4>
                                <ul>
                                    <li><Link onClick={() => window.scrollTo(0, 0)} className='footer-link' to={"/lab-for-college"}>Smart Learning</Link></li>
                                    <li><Link onClick={() => window.scrollTo(0, 0)} className='footer-link' to={"/smart-manufacturing"}>Smart Manufacturing</Link></li>
                                    <li><Link onClick={() => window.scrollTo(0, 0)} className='footer-link' to={"/contact"}>Contact Us</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links mt-1">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><Link className='footer-link' onClick={() => window.scrollTo(0, 0)} to={"/smart-manufacturing"}>Smart Manufacturing</Link></li>
                                    <li><Link className='footer-link' onClick={() => window.scrollTo(0, 0)} to={"/lab-for-college"}>Lab for College / University</Link></li>
                                    <li><Link className='footer-link' onClick={() => window.scrollTo(0, 0)} to={"/lab-for-school"}>Lab for 6th-12th Grade</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-newsletter mt-1">
                                <h4>Our Newsletter</h4>
                                <p>Revolutionizing the Future: Your Update on Smart Industry Insights</p>
                                <form onSubmit={handleSubmit}>
                                    <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    <input type="submit" value="Subscribe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Terafac</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>

            {/* ✅ Popup Message */}
            {showPopup && (
        <div className='email-popup'>
          <div className='email-popup-content'>
            <button className='email-close-button' onClick={() => setShowPopup(false)}>&times;</button>
            <p className='thank-you-message text-center'>
              Thank you for subscribing! <br />
              Meanwhile, talk to our manufacturing expert.
            </p>
          </div>
        </div>
      )}
        </>
    );
};

export default Footer;
