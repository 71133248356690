import React, { useState } from 'react'
import './mobilejobexperience.css'
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux'
import { postInformationToDiscord } from '../../action/HomePage.action'

const MobileExperienceCenter = () => {
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })

  const savedEmail = useSelector(state => state.home.email)

  const dispatch = useDispatch()

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false)
  }

  function encodeEmailToBase64 (email) {
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  const handleEmailPopupOpen = event => {
    // alert("handleEmailPopupOpen function called")
    console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

    const encodedEmail = encodeEmailToBase64(savedEmail)
    const buttonText = event.target.innerText
    const buttonName = event.target.getAttribute('name')
    const href = event.target.getAttribute('href')

    if (buttonName == 'Enter JEX') {
      if (savedEmail == '') {
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    } else if (buttonName == 'Enter Discord') {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      }
    } else {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    }
  }

  return (
    <section className='mj-job-experience-center container mj-bg-color d-flex justif-content-center align-items-center'>
      <div className='mj-job-features row d-flex align-items-center justify-content-center'>
        {/* <div className='col-md-5 d-flex justify-content-center align-items-center '>
          <div className='mj-btn-container '>
            <h5 className='mj-experience-job-heading  mj-font-family-description mj-top-level-heading'>
              Experience jobs in virtual environment !
            </h5>
            <p className='mj-font-family-description fs-6 m-0 pt-2 pb-2'>
              Work on real and simulated tools.
            </p>
            <p className='mj-font-family-description fs-6 m-0 p-0'>
              {' '}
              Interact with managers, peers and customers to get tasks done as
              you would do in a real job.
            </p>
          </div>
        </div> */}
      </div>
      <div className='mj-job-features row d-flex align-items-center justify-content-center mb-4'>
        {/* <div className='col-md-5  d-flex justify-content-center align-items-center '>
          <div className='mj-btn-container '>
            <p className='mj-font-family-description mj-custom-font-size'>
              Enter <b>Job Experience Center (JEX)</b> to explore and experience
              simulated jobs.
            </p>
             <a href='https://jex.terafac.live/'>
              <button className='mj-button mj-font-family-button mj-custom-font-size-button col-lg-6  col-sm-8 fs-lg-4 fs-sm-6'>
                Enter JEX
              </button>
            </a> 
            {/* <a
              name='Enter JEX'
              href='https://jex.terafac.live/'
              onClick={handleEmailPopupOpen}
              className='mj-button mj-custom-font-size-button col-sm-8 mj-font-family-button col-lg-6 col-md-6 text-decoration-none fs-lg-4'
            >
              Enter JEX
            </a> 
          </div>
        </div> */}
        {/* <div className='my-4 col-md-5 d-flex justify-content-center align-items-center'>
          <div className='mj-btn-container'>
            <p className='mj-font-family-description mj-custom-font-size'>
              Join our <b>discord</b> server to watch other users in action
            </p>
            <a
              name='Enter Discord'
              href='https://discord.gg/J5WKkkzEXG'
              onClick={handleEmailPopupOpen}
              className='mj-button mj-custom-font-size-button col-sm-8 mj-font-family-button col-lg-6 col-md-6 text-decoration-none fs-lg-4'
            >
              Enter Discord
            </a>
          </div>
        </div> */}
      </div>
      {showEmailPopup && (
        <EmailPopup
          buttonName={popupContent.buttonText}
          hrefUrl={popupContent.href}
          onClose={handleEmailPopupClose}
        />
      )}
    </section>
  )
}

export default MobileExperienceCenter
