import './hero.css'
import { useMediaQuery } from 'react-responsive';
import background from "../../assets/background_terafac.jpg"

const Hero = () => {
  const isMobile = useMediaQuery({ query: '(min-width: 400px) and (max-width: 415px)' })

  return (
    <section
      id='hero'
      className='hero d-flex flex-column justify-content-center'
      style={{backgroundImage: `url(${background})`,
            height: "120vh",
            marginTop: "-70px",
            fontSize: "50px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
  }}  
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-8'>
              <h1 className='identify-text'>
                <sup className='identify'>
                  Redefining
                </sup>
                <span style={{fontFamily:'Poppins, sans-serif',fontWeight:'bold'}}>
                  {`manufacturing${isMobile ? '\n': ' '}through AI ${isMobile ? '\n': ' '}&Vision`}
                </span>
              </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
